<template>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>Encuestas</h3>
        </div>
        <div class="card-body">
           <div class="row" style="justify-content: center;">
                <div class="col-md-3" style="display: grid;">
                    <label for="visita">Visita</label>
                    <select class="form-control" id="visita" style="width: 90%;" v-model="visitaSeleccionada">
                        <option value="sin visita">Selecciona una visita</option>
                        <option value=0>General (sin visita)</option>
                        <option v-for="visita in visitas" :key="visita.visita.idvisitador" :value="visita.visita.idvisitador">{{ visita.visita.nombre_operario }} {{ visita.visita.fecha }} {{ visita.visita.hora }}</option>
                    </select>
                </div>
                <div class="col-md-2" style="display: grid;">
                    <label for="encuesta">Encuesta</label>
                    <select class="form-control" id="encuesta" v-model="encuestaSeleccionada">
                        <option value="sin selec">Selecciona una encuesta</option>
                        <option v-for="encuesta in encuestas.encuestas" :value="encuesta.id_encuesta" :key="encuesta">{{ encuesta.datos.nombre_encuesta }}</option>
                    </select>
                </div>
           </div>
           <div v-if="mostrarEncuesta" style="display: grid; justify-content: center; row-gap: 10px; margin-top: 30px;">
                <p>Enviar encuesta seleccionada al cliente</p>
                <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="enviarSMS"> Envio SMS al {{ tlfCliente }} </button>
                <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="enviarEmail"> Envio email </button>
           </div>
           <div v-if="mostrarEncuesta" style="margin-top: 25px;">
                <div v-for="pregunta in encuestaAMostrar.encuesta2preguntas" :key="pregunta.id_pregunta" style="margin-top: 20px;">
                    <div v-if="pregunta.tipo_pregunta === 'Puntuación' && pregunta.estado_pregunta==0" style="display: grid; justify-content: center;">
                        <p>{{ pregunta.pregunta }}</p>
                        <div v-if="pregunta.valores_tipo_pregunta" style="display: flex; flex-wrap: wrap; justify-content: center; gap: 12px;">
                            <div v-for="valor in generarRango(pregunta.valores_tipo_pregunta)" :key="valor" style="display: grid; justify-content: center;">
                                <label>
                                    {{ valor }}
                                </label>
                                <input 
                                    type="radio" 
                                    :name="pregunta.id_pregunta" 
                                    :value="valor"
                                    v-model="respuestas[pregunta.id_pregunta]"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-else-if="pregunta.tipo_pregunta === 'Respuestas' && pregunta.estado_pregunta==0" style="display: grid; justify-content: center;">
                        <p>{{ pregunta.pregunta }}</p>
                        <div v-if="pregunta.valores_tipo_pregunta" style="display: flex; flex-wrap: wrap; justify-content: center; gap: 12px;">
                            <div v-for="valor in generarRespuestas(pregunta.valores_tipo_pregunta)" :key="valor" style="display: grid; justify-content: center;">
                                <label>
                                    {{ valor }}
                                </label>
                                <input 
                                    type="radio" 
                                    :name="pregunta.id_pregunta" 
                                    :value="valor"
                                    v-model="respuestas[pregunta.id_pregunta]"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-else-if="pregunta.tipo_pregunta === 'Texto' && pregunta.estado_pregunta==0" style="display: grid; justify-content: center;">
                        <p>{{ pregunta.pregunta }}</p>
                        <textarea  id="resp" v-model="respuestas[pregunta.id_pregunta]" rows="4"></textarea>
                    </div>
                </div>
                <div style="display: flex; justify-content: center;">
                    <button class="btn btn-sm btn-light" style="margin-top: 30px; border: 1px solid grey;" @click="guardarEncuesta">Guardar</button>
                </div>
           </div>
        </div>  
    </div>  
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio'],
    data() {
        return {
            encuestas: {},
            encuestaAMostrar:{},
            respuestas: {},
            visitas:'',
            encuestaSeleccionada:"sin selec",
            visitaSeleccionada:"sin visita",
            tlfCliente:"",
            totalPreguntas:0,
            mostrarEncuesta:false,
        }
    },
        setup() {
        return;
    },
    methods: {
        async guardarEncuesta(){
            var resps = Object.keys(this.respuestas).length;
            if(this.totalPreguntas > resps){
                alert('Hay preguntas sin responder.');
                return;
            }else{
                const api = new PwgsApi();
                const body = {"id_encuesta": this.encuestaSeleccionada, "origen":'telefonica'};
                if(this.visitaSeleccionada!=0){
                    body.id_visita = this.visitaSeleccionada;
                }
                var respuestas = {};
                for(var i = 0; i <this.encuestaAMostrar.encuesta2preguntas.length; i++) {
                    if(this.encuestaAMostrar.encuesta2preguntas[i].estado_pregunta==0){
                        var idresp = this.encuestaAMostrar.encuesta2preguntas[i].id_pregunta;
                        respuestas[idresp] = this.respuestas[idresp];
                    }
                }
                body.preguntas = respuestas;
                const response = await api.post('servicios/'+this.$props.id +'/encuestas', body);
                console.log("Encuesta guardada", response);
                alert('Encuesta guardada');
                this.encuestaSeleccionada='sin selec';
                this.visitaSeleccionada='sin visita';
            }
        },
        llenarEncuesta(){
            this.totalPreguntas = 0;
            this.respuestas = {};
            if(this.encuestaSeleccionada=='sin selec' || this.visitaSeleccionada=='sin visita'){
                this.mostrarEncuesta = false;
                return;
            } 
            try{
                const hecho = this.encuestas.respuestas[this.encuestaSeleccionada][this.visitaSeleccionada];
                if(hecho){
                    alert('Encuesta ya realizada en la visita seleccionada');
                    this.mostrarEncuesta = false;
                    return;
                }
            }catch(e){
                
                console.error("No hay respuestas");
            }
            this.mostrarEncuesta = true;
            const encuestaEncontrada = this.encuestas.encuestas.find(encuesta => encuesta.id_encuesta === this.encuestaSeleccionada);
            this.encuestaAMostrar = encuestaEncontrada;
            for(var i = 0; i<this.encuestaAMostrar.encuesta2preguntas.length; i++){
                if(this.encuestaAMostrar.encuesta2preguntas[i].estado_pregunta==0){
                    this.totalPreguntas++;
                }
            }
        },
        generarRespuestas(valores) {
            const respuests = valores.split(';');
            return respuests;
        },
        generarRango(valores) {
            const [min, max] = valores.split(';').map(Number);
            const rango = [];
            for (let i = min; i <= max; i++) {
                rango.push(i);
            }
            return rango;
        },
        async enviarSMS(){
            const api = new PwgsApi();
            const body = {"id_visita":this.visitaSeleccionada, "tipo":"SMS"};
            const response = await api.put('servicios/'+this.$props.id +'/enviar-encuesta/'+this.encuestaSeleccionada , body);
            console.log("enviado sms", response);
        },
        async enviarEmail(){
            const api = new PwgsApi();
            const body = {"id_visita":this.visitaSeleccionada, "tipo":"EMAIL"};
            const response = await api.put('servicios/'+this.$props.id +'/enviar-encuesta/'+this.encuestaSeleccionada , body);
            console.log("enviado email", response);
        },
        async cargarEncuestas(){
            //GET modulos/pwgsapi/index.php/servicios/:id/encuestas
            const api = new PwgsApi();
            this.encuestas = await api.get('servicios/' + this.$props.id + '/encuestas');
        },
        async cargarVisitas(){
            //GET modulos/pwgsapi/index.php/servicios/:id/encuestas
            const api = new PwgsApi();
            this.visitas = await api.get('servicios/'+this.$props.id+'/visitas');
        },
        cargarDatosCliente(){
            this.tlfCliente = this.$props.servicio.asegurado.telefonos[0];
        }
    },
    mounted() {
        this.cargarVisitas();
        this.cargarEncuestas();        
    },
    watch: {
        servicio(){
            this.cargarDatosCliente();
        },
        id() {
            this.cargarVisitas();
            this.cargarEncuestas();
            this.cargarDatosCliente();
            this.encuestaSeleccionada="sin selec";
            this.visitaSeleccionada="sin visita";
        },
        visitaSeleccionada(){
            this.llenarEncuesta();
        },
        encuestaSeleccionada(){
            this.llenarEncuesta();
        },
    }
})
</script>